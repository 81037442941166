<template>
  <div class="third-party-list-container">
    <CommonsSuggestions v-if="hasThirdPartySuggestions" class="--padding">
      <div
        v-for="suggestionItem in filteredThirdParties"
        :key="suggestionItem.id"
        class="third-party-suggestion-list-container"
        @click="$emit('select', { idTier: suggestionItem.id, idAccount: null })"
      >
        <span class="third-party-name">{{ suggestionItem.label }}</span>
      </div>
    </CommonsSuggestions>
    <template
      v-for="(formattedThirdPartiesToListGroup, indexGroup) in formattedThirdPartiesToListGroups"
      :key="indexGroup"
    >
      <div class="third-party-group-name-wrapper">
        <div class="third-party-group-name">{{ formattedThirdPartiesToListGroup.name }}</div>
      </div>
      <div
        v-for="(
          thirdParty, indexThirdParty
        ) in formattedThirdPartiesToListGroup.formattedThirdParties"
        :key="indexThirdParty"
        class="third-party-list-item-container"
        :class="{
          'darker-background-color': isThirdPartyAlreadySelected(
            thirdParty,
            idsThirdPartyAlreadySelected.idTier,
            idsThirdPartyAlreadySelected.idAccount,
          ),
        }"
        @click="selectThirdParty(thirdParty)"
      >
        <div class="third-party-name-wrapper">
          <span class="third-party-name"> {{ thirdParty.label }} </span>
          <span v-if="thirdParty.type === 'super generic'" class="third-party-suggest">
            Suggestion
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { CreateThirdPartyForm, IThirdParty } from "@silexpert/core";
import { ThirdPartyType } from "@silexpert/core";
import Fuse from "fuse.js";

type FormattedThirdPartiesToListGroup = {
  name: string;
  formattedThirdParties: FormattedThirdParty[];
};

const thirdPartyStore = useThirdPartiesStore();
const societyStore = useSocietyStore();

const emit = defineEmits<{
  (
    e: "select",
    data: { idTier: number | null | undefined; idAccount: number | null | undefined },
  ): void;
  (e: "close", data: undefined): void;
}>();

const props = defineProps({
  hasGenerics: {
    type: Boolean,
    required: true,
  },
  hasProviders: {
    type: Boolean,
    required: true,
  },
  hasCustomers: {
    type: Boolean,
    required: true,
  },
  hasAccountSpe: {
    type: Boolean,
    required: false,
    default: false,
  },
  isCustomer: {
    type: Boolean,
    required: true,
  },
  search: {
    type: String,
    required: false,
    default: null,
  },
  idsThirdPartyAlreadySelected: {
    type: Object as PropType<{ idTier: number | null; idAccount: number | null }>,
    required: true,
  },
  thirdPartySuggestions: {
    type: Array as PropType<
      | {
          id: number;
          company?: string;
          fullname?: string;
        }[]
      | IThirdParty[]
    >,
    required: false,
    default: null,
  },
});

const formattedThirdParties = computed<FormattedThirdParty[]>(() => {
  return thirdPartyStore.getFormattedThirdParties({
    generic: props.hasGenerics,
    accountSpe: props.hasAccountSpe,
    provider: props.hasProviders,
    customer: props.hasCustomers,
  });
});

const formattedThirdPartiesToList = computed<FormattedThirdParty[]>(() => {
  if (props.search === "" || props.search === null) {
    return formattedThirdParties.value;
  }

  const fuseOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 40,
    minMatchCharLength: 1,
    keys: ["label"],
  };

  const fuseInstanceComparingToLabel = new Fuse(formattedThirdParties.value, fuseOptions);
  return fuseInstanceComparingToLabel.search(props.search ?? "").map((fsItem) => {
    return fsItem.item;
  });
});

const formattedThirdPartiesToListGroups = computed<FormattedThirdPartiesToListGroup[]>(() => {
  const groups = [];

  const generics = formattedThirdPartiesToList.value.filter((tP) => tP.type === "generic");
  const customers = formattedThirdPartiesToList.value.filter((tP) => tP.type === "customer");
  const providers = formattedThirdPartiesToList.value.filter((tP) => tP.type === "provider");
  const accountSpe = formattedThirdPartiesToList.value.filter((tP) => tP.type === "account");
  const superThirdParties = formattedThirdPartiesToList.value.filter(
    (tP) => tP.type === "super generic",
  );

  if (generics.length > 0) {
    groups.push({
      name: "Génériques",
      formattedThirdParties: generics,
    });
  }

  if (accountSpe.length > 0) {
    groups.push({
      name: "Collaborateurs",
      formattedThirdParties: accountSpe,
    });
  }

  if (providers.length > 0) {
    groups.push({
      name: "Fournisseurs",
      formattedThirdParties: providers,
    });
  }

  if (customers.length > 0) {
    groups.push({
      name: "Clients",
      formattedThirdParties: customers,
    });
  }

  if (providers.length === 0 && !props.isCustomer) {
    groups.push({
      name: "Fournisseurs",
      formattedThirdParties: superThirdParties,
    });
  }

  return groups;
});

function isThirdPartyAlreadySelected(
  item: FormattedThirdParty,
  idTier: number | null,
  idAccount: number | null,
): boolean {
  return item.id === idTier && item.idAccount === idAccount;
}

const filteredThirdParties = computed(() => {
  return props.thirdPartySuggestions?.length
    ? props.thirdPartySuggestions
        .filter((thirdParty) => thirdParty.fullname || thirdParty.company)
        .map((thirdParty) => ({ ...thirdParty, label: thirdParty.fullname || thirdParty.company }))
    : [];
});

const hasThirdPartySuggestions = computed<boolean>(() => !!filteredThirdParties.value?.length);

const idSociety = computed(() => societyStore?.society?.id ?? 0);

async function selectThirdParty(thirdParty: FormattedThirdParty) {
  if (thirdParty.type === "super generic") {
    await saveThirdParty(thirdParty);
  } else {
    emit("select", { idTier: thirdParty.id, idAccount: thirdParty.idAccount });
  }
}

async function saveThirdParty(thirdParty: FormattedThirdParty) {
  const thirdPartyPayload = {
    company: thirdParty.label,
    soldeInit: 0,
    isGeneric: false,
    idThirdPartyType: props.isCustomer ? ThirdPartyType.CUSTOMER : ThirdPartyType.PROVIDER,
    idAccount: thirdParty.idAccount,
  };

  try {
    const newThirdParty = await $silex().thirdParty.createForSociety(
      idSociety.value,
      thirdPartyPayload as CreateThirdPartyForm,
    );

    thirdPartyStore.setNewThirdParty(newThirdParty);
    emit("select", { idTier: newThirdParty.id, idAccount: newThirdParty.idAccount });
  } catch (e) {
    $notifier().open({ type: "error", content: apiErrorToString(e) });
  }
}
</script>

<style lang="scss" scoped>
.third-party-list-container {
  width: 100%;
  height: 100%;

  .third-party-group-name-wrapper {
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: 20px;

    .third-party-group-name {
      font-size: 16px;
      line-height: 20px;
      color: $uds-neutral-700;
    }
  }
}
.third-party-list-item-container {
  padding: $uds-spacing-1 0;

  display: flex;
  align-items: center;

  &:hover {
    background-color: $uds-neutral-100;
    cursor: pointer;
  }

  &.darker-background-color {
    background-color: $uds-neutral-100;
  }

  .third-party-name-wrapper {
    display: flex;
    padding-left: $uds-spacing-5;
    width: 100%;

    .third-party-name {
      font-size: 16px;
      line-height: 16px;
      flex-grow: 1;
      width: 10px;
      color: $uds-neutral-900;
    }

    .third-party-suggest {
      font-weight: $uds-weight-450;
      margin-left: $uds-spacing-1;
      color: $uds-neutral-700;
    }
  }

  .third-party-tooltip-wrapper {
    margin-left: 14px;
    margin-right: 14px;
  }
}

.third-party-suggestion-list-container {
  display: flex;
  align-items: center;

  width: fit-content;
  max-width: 258px;

  padding: $uds-spacing-1;
  margin-bottom: $uds-spacing-1;

  background: $uds-white;
  border-radius: $uds-radius-2;
  box-shadow: 0px 2px 5px 0px #0122df0d;
  cursor: pointer;

  &.is-row {
    margin-bottom: 0;
    margin-right: $uds-spacing-1;
  }

  .third-party-name {
    font-size: 16px;
    font-weight: 100;
    color: $uds-neutral-900;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<template>
  <div class="third-party-dropdown-container">
    <div class="third-party-dropdown-search">
      <CommonsDropdownsDropdownSearch v-model:data="search" />
    </div>
    <div class="third-party-dropdown-list scrollbar">
      <CommonsDropdownsThirdPartyDropdownItemLine
        :has-generics="hasGenerics"
        :has-providers="hasProviders"
        :has-customers="hasCustomers"
        :has-account-spe="hasAccountSpe"
        :is-customer="isCredit"
        :ids-third-party-already-selected="idsThirdPartyAlreadySelected"
        :search="search ?? undefined"
        :third-party-suggestions="thirdPartySuggestions"
        @select="selectThirdParty($event.idTier ?? null, $event.idAccount ?? null)"
      />
    </div>
    <slot name="creationmodal" />

    <slot name="modal" />
  </div>
</template>

<script setup lang="ts">
import type { IThirdParty } from "@silexpert/core";

defineProps({
  hasGenerics: {
    type: Boolean,
    required: true,
  },
  hasProviders: {
    type: Boolean,
    required: true,
  },
  hasCustomers: {
    type: Boolean,
    required: true,
  },
  hasAccountSpe: {
    type: Boolean,
    required: false,
    default: false,
  },
  isCredit: {
    type: Boolean,
    required: true,
  },
  idsThirdPartyAlreadySelected: {
    type: Object as PropType<{ idTier: number | null; idAccount: number | null }>,
    required: true,
  },
  thirdPartySuggestions: {
    type: Object as PropType<
      | {
          id: number;
          company?: string;
          fullname?: string;
        }[]
      | IThirdParty[]
    >,
    required: false,
    default: null,
  },
});

const emit = defineEmits<{
  (e: "close"): void;
  (e: "select", id: { idTier: number | null; idAccount: number | null }): void;
}>();

const search = ref<string | null>(null);

function selectThirdParty(idTier: number | null, idAccount: number | null) {
  emit("select", { idTier, idAccount });
}
</script>

<style lang="scss" scoped>
.third-party-dropdown-container {
  width: 100%;
  box-shadow: 0px 6px 16px rgba(32, 33, 36, 0.12);
  border-radius: $uds-radius-1;
  background: $uds-white;

  // on every direct child
  & > * {
    border-bottom: 1px solid $uds-neutral-500;

    &:last-child {
      border-bottom: 0px;
    }
  }

  .third-party-dropdown-search {
    height: 40px;

    display: flex;
    align-items: center;
  }

  .third-party-dropdown-list {
    height: 160px;
  }
}
</style>
